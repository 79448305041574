const pathUrl = "";
// const pathUrl = "https://permisi-grad-form.firebaseapp.com";
const apiUrl = "/api/v1"
export const baseUrl = pathUrl + apiUrl;
export const formBaseUrl = '/api/v2';
export const formTitle = 'Send your wishes to our graduates!!!';
export const formDescription = 'Your wishes will be displayed in our digital graduation card, unique to each and every graduate in the below list. If there are problems with the form or any missing graduates, please contact a PERMISI committee immediately.';
export const nameLabel = 'Your Name';
export const gradLabel = 'Send to';
export const gradNoSelect = '---Select Person---';
export const wishTitleLabel = 'Wish Title';
export const wishMessageLabel = 'Your Wish';
export const uploadPhotoLabel = '(Optional) Upload your photo with them!';
export const submitButtonLabel = 'Submit your Wish';
export const richtextToolbar = [
  [{ header: [1, 2, 3, false] }],

  [{ 'font': [] }],
  [{ 'align': [] }],

  ['bold', 'italic', 'underline', 'strike', 'link'],
  ['blockquote', 'code-block'],

  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'super' }, { script: 'sub' }],
  [{ 'indent': '-1' }, { 'indent': '+1' }],
  [{ direction: 'rtl' }],

  [{ 'color': [] }, { 'background': [] }],

  ['video'],
  ['clean']
];
