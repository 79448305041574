import formInstance from '../utils/formInstance';
import { useState, useEffect } from 'react';

const batch = parseInt(process.env.REACT_APP_GRADUATION_BATCH, 10);

const useGraduates = () => {
  const [allGraduates, setGraduates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    formInstance.get(`/graduatesByBatch/${batch}`)
      .then(res => {
        setGraduates(res.data.data)
      })
      .catch(e => setError(e))
      .finally(() => {
        setLoading(false);
      })
  }, [])

  const graduateData = {
    data: allGraduates,
    error,
    loading,
  }

  return graduateData;
}

export default useGraduates;
