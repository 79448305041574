import React from 'react';
import './styles.css';
import { Modal, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CustomFade from '../CustomFade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: 4,
  },
  root: {
    color: 'rgb(160, 53, 45)'
  },
  messageContainer: {
    padding: 16,
  }
}));

const LoadingOverlay = (props) => {
  const classes = useStyles();

  const { open, message } = props;
  
  return (
    <Modal
      className={classes.modal}
      open={open}
    >
      <div>
        <CustomFade open={open}>
          <div className="loading-popup">
            <CircularProgress className={classes.root} />
            {!!message && (
              <div className="overlay-message-container">
                {message}
              </div>
            )}
          </div>
        </CustomFade>
      </div>
    </Modal>
  )
}

export default LoadingOverlay;