import React from 'react';
// import logo from './logo.svg';
import './App.css';

import Form from './containers/Form';

function App() {
  return (
    <Form />
  );
}

export default App;
