import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Logo from '../../assets/logo.png';
import formInstance from '../../utils/formInstance';
import { FormHelperText, Grow } from '@material-ui/core';
import LoadingOverlay from '../../components/LoadingOverlay';
import ErrorOverlay from '../../components/ErrorOverlay';
import FormSubmitStatusOverlay from '../../components/FormSubmitStatusOverlay';
import { formTitle, uploadPhotoLabel, submitButtonLabel, wishMessageLabel, wishTitleLabel, gradNoSelect, gradLabel, nameLabel, formDescription, richtextToolbar } from '../../utils/constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import useGraduates from '../../hooks/useGraduates';
import * as yup from 'yup';
import uploadFileAndGetFileUrl from '../../helpers/uploadFileAndGetFileUrl';

const validationSchema = yup.object({
  from: yup.string('Enter your name').required('Please type your name here.'),
  wish: yup.string('Enter your wish').required('Please type the wish message.'),
  title: yup.string('Wish title').required('Please enter a title for your wish.'),
  graduateId: yup.string('Enter your graduate ID').required('You need to select a graduate to send your wish to.'),
});

const useStyles = makeStyles({
  root: {
    background: '#a0352d',
    border: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
    alignItems: 'center'
  },
  buttonProgress: {
    color: '#a0352d',
    position: 'absolute'
  },
  wishInputContainer: {
    width: '100%',
    padding: '4px 0 16px',
  }
});

const readAsURL = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  
  reader.onloadend = async (e) => {
    resolve(reader.result);
  }

  reader.onerror = reject;
    
  reader.readAsDataURL(file);
})

const Form = () => {
  const classes = useStyles();
  const { data: graduates, loading: loadingGraduates } = useGraduates();
  const [imageBuffers, setImageBuffers] = useState([]);
  const [progressMessage, setProgressMessage] = useState('Loading graduates...');
  const [isProcessingImages, setProcessingImages] = useState(false);
  const [formSubmitResponse, setFormSubmitResponse] = useState(null)
  const [errorMessage, setErrorMessage] = useState('');

  const imgRef = React.createRef();

  const handleSubmit = async (values) => {
    const { from, graduateId, title, wish, imageFiles } = values;
    const images = await uploadFileAndGetFileUrl({
      files: imageFiles,
      onProgress: ({ message }) => {
        setProgressMessage(message);
      }
    });

    const wishData = { 
      from: from.trim(), 
      message: wish.trim(), 
      title: title.trim(), 
      graduateId: graduateId,
      images,
    };

    setProgressMessage('Finalizing upload...');
    await formInstance.post('/submitWish', wishData)
      .then(res => {
        /*
        res contents: data, status, statusText(can be used as title)
        */
        setFormSubmitResponse(res);
      })
      .catch(err => {
        setFormSubmitResponse(err.response)
      });
    setProgressMessage(null);

      // eslint-disable-next-line no-undef
      if (gtag) {
        // eslint-disable-next-line no-undef
        gtag('event', 'Submit Graduate Wish', {
          'event_category' : 'Graduate Wish Form',
          'event_label' : 'Submit'
        });  
      }
  }

  const formik = useFormik({
    initialValues: {
      from: '',
      wish: '',
      title: '',
      graduateId: '',
      imageFiles: []
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const resetForm = () => {
    formik.resetForm();
    setImageBuffers([]);
    setFormSubmitResponse(null);
  }


  const nameInputProps = {
    placeholder: 'Enter your name',
  }
  return (
    <div id="form-container">
      <LoadingOverlay
        open={formik.isSubmitting || loadingGraduates}
        message={progressMessage}
      />
      <ErrorOverlay errorMessage={errorMessage} onClose={() => setErrorMessage('')} />
      <FormSubmitStatusOverlay submitResponse={formSubmitResponse} resetForm={resetForm} />
      <div id="logo-container">
        <img src={Logo} alt="PERMISI Logo" className="permisi-logo form-logo"/>
      </div>
      <h2>{formTitle}</h2>
      <span id="form-description">{formDescription}</span>
      <Box id="form-contents">
        <form onSubmit={formik.handleSubmit}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {nameLabel}
          </InputLabel>
          <TextField
            id="from"
            name="from"
            type="text"
            className="form-field"
            inputProps={nameInputProps}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            error={formik.touched.from && Boolean(formik.errors.from)}
            helperText={formik.touched.from && formik.errors.from}
            onChange={formik.handleChange}
            value={formik.values.from}
          />
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {gradLabel}
          </InputLabel>
          <Select
            id="graduateId"
            name="graduateId"
            label="Select"
            native
            className="form-field"
            inputProps={nameInputProps}
            placeholder="Choose a graduating senior"
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.graduateId}
          >
            <option value="">
              {gradNoSelect}
            </option>
            {graduates.map(({ name, id }) => {
              return (
                <option value={id} key={id}>
                  {name}
                </option>
              )
            })}
          </Select>
          {formik.touched.graduateId && Boolean(formik.errors.graduateId) && (
            <FormHelperText error dense style={{ margin: '0px 14px 8px' }}>
              {formik.touched.graduateId && formik.errors.graduateId}
            </FormHelperText>
          )}
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {wishTitleLabel}
          </InputLabel>
          <TextField
            id="title"
            type="text"
            name="title"
            className="form-field"
            inputProps={{
              placeholder: 'Enter wish title'
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {wishMessageLabel}
          </InputLabel>
          <div className={classes.wishInputContainer}>
            <ReactQuill
              theme="snow"
              modules={{
                toolbar: richtextToolbar
              }}
              onChange={(updatedWish) => {
                formik.setFieldValue("wish", updatedWish)
              }}
              value={formik.values.wish}  
            />
            {formik.touched.wish && Boolean(formik.errors.wish) && (
              <FormHelperText error dense style={{ margin: '8px 14px 0px' }}>
                {formik.touched.wish && formik.errors.wish}
              </FormHelperText>
            )}
          </div>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {uploadPhotoLabel}
          </InputLabel>
          <Button
            variant="contained"
            component="label"
            id="img-upload"
            disabled={isProcessingImages || formik.isSubmitting}
          >
            <span>Select Image(s)</span>
            <input
              type="file"
              ref={imgRef}
              style={{ display: "none" }}
              accept=".jpg, .png, .bmp, .jpeg"
              multiple
              onChange={async (e) => {
                setProcessingImages(true);
                const files = e.target.files;
                const fileArray = [];
                for (const file of files) {
                  fileArray.push(file);
                }
                const fileBufferList = await Promise.all(
                  fileArray.map((file) => readAsURL(file)),
                )
                setImageBuffers(fileBufferList);
                formik.setFieldValue("imageFiles", fileArray);
                setProcessingImages(false);
              }}
            />
          </Button>
          {
            <Grow in={Boolean(formik.values.imageFiles.length)} timeout={1500}>
              <div id="img-upload-preview-container" >
                {
                  imageBuffers.map((image, index) => {
                    return (
                      <div className="image-upload-preview" key={index}>
                        <img src={image.toString('base64')} id="img-upload-preview" alt="Uploaded Memory" />
                      </div>  
                    )
                  })
                }
              </div>
            </Grow>
          }
          <Button
            variant="contained"
            color="secondary"
            size='large'
            className="form-submit"
            onClick={formik.submitForm}
            classes={{
              root: classes.root, // class name, e.g. `classes-nesting-root-x`
              label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
            disabled={formik.isSubmitting}
          >
            {submitButtonLabel}
          </Button>
        </form>
      </Box>
    </div>
  );
}

export default Form;
