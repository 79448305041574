import React from 'react';
import './styles.css';
import { Modal, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CustomFade from '../CustomFade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: 4,
  },
  root: {
    color: 'brown'
  }
}));

const ErrorOverlay = (props) => {
  const classes = useStyles();

  const { errorMessage, onClose } = props;
  const open = Boolean(errorMessage);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
    >
      <div>
        <CustomFade open={open}>
          <div className="error-popup">
            {errorMessage}
          </div>
        </CustomFade>
      </div>
    </Modal>
  )
}

export default ErrorOverlay;