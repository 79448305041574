import axios from 'axios';
import formInstance from '../utils/formInstance';

function getPathFromUrl(url) {
  return url.split("?")[0];
}

const getProxiedUploadUrl = (url) => url.replace('https://storage.googleapis.com/', 'https://');

const uploadFileAndGetFileUrl = async ({
  files,
  onProgress,
}) => {
  // Get upload URL of the different images
  const imageURLList = [];
  const headersList = [];
  for (let i = 0; i < files.length; i++) {
    onProgress({ message: `Preparing to upload image(s) ${i + 1}/${files.length}` });
    const imageFile = files[i];
    const filename = imageFile.name;
    const contentLength = imageFile.size;
    const contentType = imageFile.type;
    const generateSignedUrlResponse = await formInstance.post('/generateSignedUrl', {
      filename,
      contentLength,
      contentType,
    });
    const { directUploadUrl, headers } = generateSignedUrlResponse.data;
    imageURLList.push(directUploadUrl);
    headersList.push(headers);
  }

  // Perform PUT upload for the different images in Firebase
  for (let i = 0; i < files.length; i++) {
    const imageFile = files[i];
    const contentLength = imageFile.size;
    const uploadUrl = imageURLList[i];
    const headers = headersList[i];
    onProgress({ message: `Uploading images ${i + 1}/${files.length}` });
    await axios.put(uploadUrl, imageFile, {
      headers,
      onUploadProgress: (progressEvent) => {
        const currentProgress = (progressEvent.loaded * 100) / contentLength;
        onProgress({ message: `Uploading images ${i + 1}/${files.length} (${Math.round(currentProgress)}%)` });
      }
    });
  }

  return imageURLList.map((url) => getPathFromUrl(getProxiedUploadUrl(url)));
}

export default uploadFileAndGetFileUrl;
