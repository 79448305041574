import React from 'react';
import { Fade } from '@material-ui/core';

const CustomFade = ({open, children}) => (
  <Fade
    in={open}
    timeout={{
      appear: 400,
      enter: 200,
      exit: 100,
    }}
  >
    {children}
  </Fade>
);

export default CustomFade;