import React from 'react';
import './styles.css';
import { Modal, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CustomFade from '../CustomFade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    padding: 4,
  },
  root: {
    background: 'rgb(160, 53, 45)',
    color: 'white',
    marginTop: 8,
  }
}));

const FormSubmitStatusOverlay = (props) => {
  const classes = useStyles();

  const { submitResponse, resetForm } = props;
  const { message, status } = submitResponse || {};

  const statusTitle = status === 200 ? 'Success' : 'Error';
  const StatusIcon = status === 200 ? CheckCircleIcon : ErrorIcon;
  const open = !!submitResponse;

  return (
    <Modal
      className={classes.modal}
      open={open}
    >
      <div>
        <CustomFade open={open}>
          <div className="status-popup">
            <h2>{statusTitle}</h2>
            <StatusIcon className="status-icon" style={{color: status === 201 ? '#007E33' : '#CC0000'}} />
            <span>{message}</span>
            <Button className={classes.root} onClick={resetForm}>Submit Another Wish</Button>
          </div>
        </CustomFade>
      </div>
    </Modal>
  )
}

export default FormSubmitStatusOverlay;